@import '../../../../assets/styles/mixins';

.label {
  display: inline-block;
  margin-bottom: 20px;
}

.value {
  @include flexbox(flex-start, flex-start);

  & > *:first-child {
    margin-right: 40px;
  }
}

@media screen and (max-width: 560px) {
  .value {
    display: block;
  }
}