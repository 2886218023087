// firefox

html, body {
  scrollbar-color: #ffffff00 #0000008f; /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin; /* толщина */
}

// webkit
* {
  ::-webkit-scrollbar {
    width: 8px; /* ширина для вертикального скролла */
    height: 8px; /* высота для горизонтального скролла */
    background-color: rgba(0, 0, 0, 0);
  }
  
  /* ползунок скроллбара */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.4);
    border-radius: 40px;
    border: 1px solid white;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,0.7);
  }
  
  /* arrows */
  ::-webkit-scrollbar-button:vertical:start:decrement {
    display: none;
  }
  
  ::-webkit-scrollbar-button:vertical:end:increment {
    display: none;
  }
  
  ::-webkit-scrollbar-button:horizontal:start:decrement {
    display: none;
  }
  
  ::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
  }
}