@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.modalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $personal-bg;

}

.hidden {
  display: none;
}

.title {
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  font-weight: normal;
  letter-spacing: 0.0025em;
  margin-bottom: 50px;
}

.welcomeModal {
  max-width: 600px;
  padding: 0;
  box-sizing: border-box;
  background-color: inherit;
}

.confirmBtns {
  max-width: 440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.04em;
  text-transform: uppercase;

}

.toMain,
.toPersonal {
  width: 48%;
  max-width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  border: 1px solid black;
}

.toPersonal {
  background-color: black;
  color: white;
}
