$defaultFlexBoxAlign: center;
$defaultFlexBoxJustify: center;

@mixin noPhoto {
  // position: relative;
  // &:after {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // content: ' ';
    // background-image: url('../img/no-image.svg');
    // background-color: #f6f6f6;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    // z-index: -1;
  // }
}

@mixin mainDarkBg {
  background-color: $header-bg-color-vid-bg;
  // background-image: url('../img/main-bg_quad.png');
}

@mixin canSelect {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text;   /* Chrome/Safari/Opera */
  -khtml-user-select: text;    /* Konqueror */
  -moz-user-select: text;      /* Firefox */
  -ms-user-select: text;       /* Internet Explorer/Edge */
  user-select: text;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

@mixin canNotSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

@mixin flexbox($align: $defaultFlexBoxAlign, $justify: $defaultFlexBoxJustify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin inlineFlexbox($align: $defaultFlexBoxAlign, $justify: $defaultFlexBoxJustify) {
  display: inline-flex;
  align-items: $align;
  justify-content: $justify;
}

@function toRem($size) {
  $remSize: $size / $defaultFontSize;
  @return #{$remSize}rem;
}


@mixin for-desktop-big {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin for-desktop-sm {
  @media screen and (max-width: 980px) {
    @content;
  }
}

@mixin for-tablet  {
  @media screen and (max-width: 720px) {
    @content;
  }
}

@mixin for-tablet640 {
  @media screen and (max-width: 640px) {
    @content;
  }
}

@mixin for-phone {
  @media screen and (max-width: 480px) {
    @content;
  }
}

// Media request mixins MobileFirst
@mixin forDesktopBig {
  @media screen and (min-width: 981px) {
    @content;
  }
}

@mixin forDesktopSm {
  @media screen and (min-width: 721px) {
    @content;
  }
}

@mixin forTablet {
  @media screen and (min-width: 481px) {
    @content;
  }
}

@mixin btnHoverFromPrToSecValue {
  border-color: $color-secondary;
  background-color: $color-primary;
  color: $color-secondary;
}

@mixin btnHoverFromPrToSec {
  border-color: 1px $color-primary;
  color: $color-primary;
  background-color: $color-secondary;
  transition: 0.2s all;

  &:hover {
    @include btnHoverFromPrToSecValue;
  }
}

@mixin resetBtn {
  display: block;
  color: $color-extra;
  font-size: 12px;
  text-decoration: underline;
}

@mixin resetList {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin arrowLinkBtn {
  &:after {
    content: '\2192';
    margin-left: 10px;
    font-size: 16px;
  }
}

@mixin list-style-type {
  @include flexbox(flex-start, flex-start);

  &:before {
    $size: 5px;

    content: '';
    display: block;
    flex-shrink: 0;
    margin-right: 15px;
    margin-top: 5px;
    width: $size;
    height: $size;
    background-color: $color-primary;
  }
}

@mixin page-list {
  @include flexbox(flex-start, space-between);

  .sidebar {
    flex-shrink: 0;
    margin-right: 20px;
  }

  .content {
    flex-grow: 1;
  }

  @include for-desktop-big() {
    flex-wrap: wrap;

    .sidebar {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 50px;
    }

    .content {
      flex-basis: 100%;
    }
  }
}

@mixin media-cards-list {
  @include flexbox(flex-start, flex-start);
  flex-wrap: wrap;

  .item {
    $offset: 20px;
    $count-row: 3;

    flex-basis: calc(33.3% - #{$offset * 2 / $count-row});
    margin-bottom: $offset * 3;

    &:not(:nth-child(3n + #{$count-row})) {
      margin-right: $offset;
    }

    & > a {
      width: 100%;
    }
  }

  @include for-desktop-sm {
    .item {
      $count-row: 2;
      $offset: 20px;

      flex-basis: calc(50% - #{$offset / 2});
      margin-bottom: $offset * 2;

      &:not(:nth-child(3n + 3)) {
        margin-right: 0;
      }

      &:nth-child(odd) {
        margin-right: $offset;
      }
    }
  }

  @include for-phone {
    .item {
      $count-row: 1;

      flex-basis: 100%;

      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  }
}

@mixin hiddenLink {
  .hiddenLink {
    position: absolute;
    z-index: -10000;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}