
.icon-search:before { content: '\ee30'; } /* '' */
.icon-dropdown:before { content: '\e877'; } /* '' */
.icon-profile:before { content: '\e802'; } /* '' */
.icon-help:before { content: '\e803'; } /* '' */
.icon-basket:before { content: '\e804'; } /* '' */
.icon-order:before { content: '\e805'; } /* '' */
.icon-analytic:before { content: '\e806'; } /* '' */
.icon-calendar:before { content: '\e807'; } /* '' */
.icon-feed:before { content: '\e808'; } /* '' */
.icon-notification:before { content: '\e809'; } /* '' */
.icon-exit:before { content: '\e812'; } /* '' */
.icon-download:before { content: '\ed30'; } /* '' */
.icon-down-open:before { content: '\e87b'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-telegram:before { content: '\f1d8'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
