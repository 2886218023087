@import 'assets/styles/variables';
@import 'assets/styles/mixins';
@import 'assets/styles/fonts';
@import 'assets/styles/fontello';
@import 'assets/styles/scrollBar';

// $defaultFont: 'InterRegular';
$defaultFont: 'Inter';

html {
  background-color: white;
}

:root {
  --color-black: #1C1C1C;
  --color-light-black: #141414;
  --color-white: #fff;
  --color-gray: #B0B0B0;
  --color-gray-300: #E3E3E3;
  --color-gray-400: #EBEBEB;
  --color-gray-500: #151315;
  --color-gray-600: #666666;
  --color-gray-700: #CDCDCD;
  --color-gray-800: #9C9C9C;
  --color-light-gray: #F6F6F6;
  --color-red: #EF2B23;
  --color-green: #4CAF50;
  --color-light-red: #F44336;
  --color-yellow: #FFEB3B;
  
  
  --color-dark-red: #DA1E28;
  --color-blue: #0F62FE;

  --color-primary: var(--color-black);
  --color-secondary: var(--color-white);
  --color-extra: var(--color-gray);
  --color-passive: var(--color-gray-700);
  --color-error: var(--color-red);

  --color-bg-light: var(--color-light-gray);
}

html,
body,
div[id="root"] {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: $defaultFont, Arial, Helvetica, sans-serif;
  background-color: #fff;
  color: $color-primary;
  border: 0;
}

div[id="root"] {
  @include flexbox(center, flex-start);
  flex-direction: column;

  min-width: 320px;

  & > * {
    width: 100%;
  
    @include canNotSelect;
  }
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
*,
::after,
::before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

p {
  margin: 0;
}

ul, ol {
  margin: 0 0 0 16px;
  padding: 0;

  li {
    list-style-type: none;
  }
  li.text-bullets  {
    list-style-type: square;
    line-height: 150%;
    margin-bottom: 15px;
    * {margin: 0;}
  }
  li.text-numbers {
    list-style-type: decimal;
    line-height: 150%;
    margin-bottom: 15px;
    * {margin: 0;}
  }
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

button {
  box-shadow: none;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

img {
  max-width: 100%;
}


.Toastify {
  &__toast {
    &-container {
      width: 350px;
    }

    &--success,
    &--info,
    &--error {
      background: white;
      padding-left: 80px;
      color: black;
      border-left: 5px solid;
      background-repeat: no-repeat;
      background-size: 34px 34px;
      background-position: 25px center;  
    }
    &--success {
      border-left-color: #21A67A;
      background-image: url('assets/img/m-success.png');
    }
    &--info {
      border-left-color: #007BC2;
      background-image: url('assets/img/m-info.png');
    }
    &--error {
      border-left-color: #FF0000;
      background-image: url('assets/img/m-error.png');
    }
    
    &-body {
      font-family: $defaultFont, Arial, Helvetica, sans-serif;
      font-size: 14px;
      color: #141414;
      font-weight: 600;
    }
  } 
  &__close-button {
    color: #141414;
  }
}

.regular-text {
   font-size: 16px;
   line-height: 150%;
   margin-bottom: 24px;
}

.text-blockquote {
  margin: 60px 0;
  font-size: 16px;
  line-height: 150%;
  padding: 40px;
  background-color: #f6f6f6;
}

.text-title {
  margin-bottom: 40px;
  font-weight: 400;
}

.text-subtitle {
  font-size: 16px;
  line-height: 150%;
  font-weight: bold;
  margin-bottom: 24px;
}

.text-image {
  margin: 0 30px 0 60px;
}

.text-light {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 24px;
  color: #969696;
}

.text-blockquote_mark {
  margin: 60px 0;
  font-size: 24px;
  line-height: 150%;
  color: #141414;
  padding: 20px;
  background-image: url('assets/img/blockquote_mark.png');
  background-size: 74px 61px;
  background-position: left top;
  background-repeat: no-repeat;
}
