@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-SemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}