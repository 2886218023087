$defaultFont: 'Arial';

// Some variables: colors, sizes, etc
$color-primary: var(--color-primary);
$color-secondary: var(--color-secondary);
$color-extra: var(--color-extra);
$color-passive: var(--color-passive);
$color-error: var(--color-error);

$color-bg-light: var(--color-bg-light);
$color-bg-dark: $color-extra;

$legend-color-green: #34A853;
$legend-color-blue: #0F62FE;
$legend-color-red: #DA1E28;
$legend-color-yellow: #FBBC05;
$legend-color-gray: #E2E2E2;

$personal-bg: #F9F9F9;

$AdeptTest-color-high: #57627A;
$AdeptTest-color-mid: #DADADA;
$AdeptTest-color-low: #B71A3C;
$AdeptTest-color-passed: #C6CBDC;
$AdeptTest-color-failed: #EFC0C5;

$color-bg-modal: var(--color-white);

$header-login-height: 110px;

$header-height: 80px;
$header-height-mobile: 70px;
$header-bg-color: var(--color-white);
$header-bg-color-dark: var(--color-gray-500);
$header-bg-color-vid-bg: #141414;

$sidebar-bg-color-active-link: var(--color-gray-300);
$sidebar-bg-color: var(--color-light-gray);

$time-animation: 0.25s;

$color-sublink: var(--color-gray-600);

$header-separator-color: var(--color-gray-400);
$header-link-color: var(--color-gray-800);

$icon-in-circle-bg-color: var(--color-light-gray);

$block-bg-color-1: var(--color-light-black);
$block-bg-color-2: var(--color-light-gray);
