.report-wrapper {
  .quiz-report {
    display: flex;
    flex-direction: column;
    width: 45.47rem;

    h1 {
      margin: 0;
      margin-top: 2.34rem;
      font-size: 1.31rem;
      line-height: 1.41rem;
      text-align: center;
      text-transform: uppercase;
    }
  }


  .validation-info {
    margin-top: 1.41rem;
    display: flex;
    gap: 0.47rem;
    flex-direction: column;
  }

  .validation-info dl {
    display: flex;
    margin: 0;
    gap: 0.94rem;
  }

  .validation-info dl dt,
  .validation-info dl dd {
    font-size: 1.13rem;
    line-height: 1.31rem;
    margin: 0;
  }

  .validation-info dl dt {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4)
  }

  .validation-info dl dd {
    font-weight: bold;
  }

  .result {
    border: 1px solid transparent;
    padding: 0.94rem;
    margin-top: 2.34rem;
  }

  .result.success {
    border-color: rgba(98, 176, 255, 0.3);
  }

  .result.fail {
    border-color: rgba(182, 45, 78, 0.3);
  }

  .result h3 {
    font-size: 1.03rem;
    line-height: 1.43rem;
    font-weight: normal;
  }

  .result p {
    font-size: 0.94rem;
    line-height: 1.31rem;
    color: rgba(0, 0, 0, 0.6);
  }

  .result .score {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .chart {
    height: 25.78rem;
    width: auto;
    margin-top: 2.81rem;
  }

  .dim-results {
    display: flex;
    gap: 1.69rem;
    margin: 4.22rem 0;
  }

  .dim-results .column {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.94rem;
  }

  .dim-results .header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.13rem;
    line-height: 1.31rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.56rem;
  }

  .dim-results .column.high .header,
  .dim-header.high {
    background: rgba(98, 176, 255, 0.3);
  }

  .dim-results .column.mid .header,
  .dim-header.mid {
    background: rgba(179, 179, 179, 0.3);
  }

  .dim-results .column.low .header,
  .dim-header.low {
    background: rgba(182, 45, 78, 0.3);
  }

  .dim-results .items {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    font-size: 1.03rem;
    line-height: 1.41rem;
    row-gap: 0.56rem;
    column-gap: 0.19rem;
  }

  .dim-results .index,
  .dim-results .score {
    font-weight: bold;
  }

  .dim-results .index {
    padding-left: 0.94rem;
  }

  .dim-results .score {
    position: relative;
    padding-left: 1.4rem;
  }

  .dim-results .score::after {
    content: ' ';
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    left: 0.6rem;
    top: 0.66rem;
    transform: translate(-50%, -50%) rotate(45deg);
    border: 1px solid transparent;
  }

  .dim-results .column.high .score::after,
  .dim-header.high .score::after {
    border-color: rgb(98, 176, 255);
  }

  .dim-results .column.mid .score::after,
  .dim-header.mid .score::after {
    border-color: rgb(179, 179, 179);
  }

  .dim-results .column.low .score::after,
  .dim-header.low .score::after {
    border-color: rgb(182, 45, 78);
  }

  h2,
  h3 {
    font-size: 1.13rem;
    line-height: 1.5rem;
    font-weight: bold;
    margin: 0;
    margin-top: 1.41rem;
    text-align: left;
  }

  h2 {
    text-transform: uppercase;
  }

  .dim-header,
  .methodology-header {
    display: flex;
    margin-top: 0.94rem;
    padding: 0.28rem 0.56rem;
  }

  .methodology-header {
    padding-left: 0;
  }

  .dim-header .index,
  .methodology-header .index {
    padding-right: 0.47rem;
    margin-right: 0.75rem;
    border-right: 1px solid black;
  }

  .dim-header .text {
    margin-right: 1.03rem;
  }

  .dim-header .score {
    position: relative;
    padding-left: 1.69rem;
  }

  .dim-header .score::after {
    content: ' ';
    width: 0.7rem;
    height: 0.7rem;
    position: absolute;
    left: 0.5rem;
    top: 0.7rem;
    transform: translate(-50%, -50%) rotate(45deg);
    border: 1px solid transparent;
  }

  p,
  ul > li {
    margin: 0;
    margin-top: 0.47rem;
    font-size: 1.03rem;
    line-height: 1.5rem;
    text-align: justify;
  }

  ul {
    margin: 0;
    padding: 0;
    padding-left: 1.5rem;
  }

  .methodology-chart-block {
    display: flex;
    margin-top: 1.88rem;
    font-size: 0.94rem;
    line-height: 1.13rem;
  }

  .score-label,
  .dim-label {
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
  }

  .score-label {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    padding-left: 0.75rem;
  }

  .dim-label {
    margin-top: -0.75rem;
  }

  .legend {
    margin-left: 2.16rem;
  }

  .legend .dims > *,
  .legend .classes > * {
    margin-bottom: 0.47rem;
  }

  .legend .classes {
    margin-top: 0.94rem;
  }

  .legend .classes > * {
    padding-left: 1.13em;
    position: relative;
  }

  .legend .classes > *::after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0.5rem;
    transform: translatey(-50%);
    border-radius: 0.09rem;
    width: 0.56rem;
    height: 0.56rem;
  }

  .legend .classes .high::after {
    background: rgb(98, 176, 255);
  }

  .legend .classes .mid::after {
    background: rgb(179, 179, 179);
  }

  .legend .classes .low::after {
    background: rgb(182, 45, 78);
  }

  .methodology-chart {
    height: 20.72rem;
    width: auto;
  }
}
